import {
  library,
  dom
} from '@fortawesome/fontawesome-svg-core';
import {
  faArrowAltCircleRight,
  faChevronLeft,
  faCircleNotch,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faEnvelopeOpen,
  faPhone,
  faSearch,
  faSignInAlt,
  faSignOutAlt,
  faStoreAlt,
  faTimes,
  faToggleOff,
  faToggleOn,
  faUserCircle,
  faUsersCog,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';

library.add([
  faArrowAltCircleRight,
  faChevronLeft,
  faCircleNotch,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faEnvelopeOpen,
  faPhone,
  faSearch,
  faSignInAlt,
  faSignOutAlt,
  faStoreAlt,
  faTimes,
  faToggleOff,
  faToggleOn,
  faUserCircle,
  faUsersCog,
  faVideo,
]);

// This will kick of the initial replacement of i to svg tags and configure a MutationObserver
dom.watch();

console.log('Hello from FA');
